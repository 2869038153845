import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.scss';

function HoCaroursel(props) {
    return (
        <Carousel className="carouselStylesWrapper" {...props}>
            {props.children}
        </Carousel>
    )
}

export default HoCaroursel
